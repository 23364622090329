<template>
  <Layout>
    <div class="International">
      <el-form
        ref="form"
        :rules="rules"
        size="small"
        label-width="150px"
        label-position="left"
      >
        <el-form-item
          label="标题栏"
          prop="title"
        >
          <el-input
            v-model="form.title"
            type="textarea"
            :autosize="{ minRows: 3 }"
            placeholder="多行输入"
          />
        </el-form-item>
        <el-form-item
          label="简介"
          prop="desc"
        >
          <el-input
            v-model="form.desc"
          />
        </el-form-item>
        <el-form-item
          label="正文"
          prop="content"
          style="padding-right: 10px;"
        >
          <Tinymce
            v-model="form.content"
            :height="300"
          />
        </el-form-item>
      </el-form>
      <div
        class="International-btn"
      >
        <el-button
          :disabled="codeDisabled"
          @click="handleConfirm"
        >
          保存
        </el-button>
      </div>
    </div>
  </Layout>
</template>

<script>
import { userZoneList } from '@/utils/selectOptions.js'
import { USER_AREA } from '@/enum'
import { cloneDeep } from 'lodash'
import Tinymce from '@/components/Tinymce/index'
import { mapState } from 'vuex'
import {
  newsAdd, newsEdit, newsGet
} from '@/api/Userfeedback.js'
const GAME_STATUS = {
  ON: 1,
  OFF: 2
}
const emptyModeData = {
  gamePlatform: 0,
  rentalSupport: 0,
  epicId: '',
  accountGroup: '',
  offlineMode: 0,
  startProcess: '',
  startPath: '',
  windowTitle: '',
  windowClass: '',
  preStartPath: '',
  withoutAccMode: 0,
  withoutAccStartPath: '',
  withoutAccProcessName: ''
}
export default {
  name: 'EditNews',
  components: { Tinymce },
  data () {
    // const gameStatusValidator = (rule, value, callback) => {
    //   if (this.area === USER_AREA.History && this.form.gameStatus === GAME_STATUS.OFF) {
    //     callback(new Error('历史数据必须启用'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      USER_AREA,
      GAME_STATUS,
      codeDisabled: false,
      form: {
        title: '',
        content: '',
        desc: ''
      },
      rules: {
        content: [{ required: true, message: '正文是必填项' }]
      }
    }
  },
  computed: {
    disabled () {
      return this.form.gameStatus === GAME_STATUS.OFF
    },
    ...mapState('app', ['appId', 'channel', 'pkgType'])
  },

  created () {
    if (this.$route.query.id) {
      newsGet({
        id: this.$route.query.id
      })
        .then(res => {
          if (res.code === 200) {
            this.form = res.data
          }
        })
        .catch(() => {
          this.showDialog = false
        })
        .finally(() => {
          this.updateLoading = false
          this.showDialogLoading = false
        })
    }
  },
  methods: {
    handleConfirm () {
      this.codeDisabled = true
      setTimeout(() => {
        this.codeDisabled = false
      }, 60000)
      if (this.$route.query.id) {
        newsEdit({
          ...this.form,
          id: this.$route.query.id,
          appId: this.appId,
          channel: this.channel,
          pkgType: this.pkgType
        })
          .then(res => {
            if (res.code === 200) {
              this.$message.success('修改成功')
              this.$router.go(-1)
              this.showDialog = false
            }
          })
          .catch(() => {
            this.showDialog = false
          })
          .finally(() => {
            this.updateLoading = false
            this.showDialogLoading = false
          })
      } else {
        newsAdd({
          ...this.form,
          appId: this.appId,
          channel: this.channel,
          pkgType: this.pkgType
        })
          .then(res => {
            if (res.code === 200) {
              this.$message.success('新增成功')
              this.$router.go(-1)
              this.showDialog = false
            }
          })
          .catch(() => {
            this.showDialog = false
          })
          .finally(() => {
            this.updateLoading = false
            this.showDialogLoading = false
          })
      }
    },
    validate () {
      const baseFiledList = [
        'gameStatus',
        'gameName',
        'gameDesc',
        'content',
        'pcBackPath',
        'headerImage',
        'headerImageVertical',
        'loadCarousel',
        'timeInterval'
      ]
      return new Promise(resolve => {
        Promise.all([
          this.checkFormValidate(),
          this.$refs.FormItemForGameCoverH.validate(),
          this.$refs.FormItemForGameCoverV.validate(),
          this.$refs.FormItemForLoadingSwiper.validate(),
          this.$refs.FormItemForStartMode.validate()
        ])
          .then(list => {
            const last = list.pop()
            const baseObj = Object.assign(...list)
            const startModeObj = Object.assign(emptyModeData, last)

            baseFiledList.forEach(key => {
              delete startModeObj[key]
            })
            resolve(Object.assign(baseObj, startModeObj))
          })
          .catch(error => {
            this.$emit('validateFail', {
              area: this.area,
              message: error.message
            })
          })
      })
    },
    checkFormValidate () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(cloneDeep(this.form))
          } else {
            const result = userZoneList.find(item => item.value === this.area)
            reject(new Error(result.name + '校验未通过, 请检查相关表单'))
          }
        })
      })
    }
  }
}
</script>
<style scoped>
.International-btn {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100px;
}
</style>
